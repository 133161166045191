<template>
  <div id="myselfPrivacy">
    <Back title="隐私条款"></Back>
    <main class="box">
      <h5 class="bold">特别提示:</h5>
      <p class="mb20 bold">
        请您完整地阅读本《东业隐私权条款》（下称“本隐私权条款”），作出您认为适当的选择。如果您不同意本隐私权条款的任何内容，您应立即停止使用东业智慧园区平台服务。当您使用东业智慧园区平台提供的任一服务时，即表示您已同意我们按照本隐私权条款来合法使用和保护您的个人信息。
      </p>
      <p>
        1、我们仅在本隐私条款所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。东业智慧园区会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，东业智慧园区将尽力做到使您的信息不被泄漏、毁损或丢失。
      </p>
      <p>
        2、您的账户均有安全保护功能，请妥善保管您的账户及密码信息。东业智慧园区将通过向其它服务器备份、对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您理解，由于技术的限制以及可能存在的各种恶意手段，即便竭尽所能加强安全措施，在信息网络上也不存在“完善的安全措施”。如因您自己的原因导致账户及密码信息泄露而造成的任何法律后果需由您本人负责。
      </p>
      <p>
        3、在使用东业智慧园区平台服务进行网上交易时，如您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息（例如联系人、联络方式等），请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息已经被泄露或者存在被泄露的可能，且有可能会危及您注册获得的东业智慧园区账户安全，或者给您造成其他的损失的，请您务必在第一时间通知东业智慧园区客服，以便东业智慧园区采取相应措施维护您的东业智慧园区账户安全，防止损失的发生或者进一步扩大。为避免疑义，如果您未在第一时间通知东业智慧园区客服，则您应自行承担由此给您造成的损失（及扩大的损失）。
      </p>
      <p>
        4、如您对本隐私权条款有任何疑问或您提供的上述个人信息受到了侵扰，您可以通过东业智慧园区网站首页下方的违法信息举报电话与我们联系，我们将在接到投诉后尽快给予您答复。
      </p>
      <p>
        5、东业智慧园区有权对本隐私权条款进行更新，以反映我们的信息操作的变更。如果我们对其作了任何重大变更，我们会在该等变更生效之前，通过站内信、邮件或在网站上挂出通知的方式通知您，您仍继续使用东业智慧园区提供的服务，即表示您同意受经修订本隐私权条款的约束。我们鼓励您定期地查阅本网页上关于我们隐私实践的最新信息。
      </p>
      <p>
        6、东业智慧园区将致力于保障您个人信息的私密性和安全性，并让您能够自主控制。本隐私权条款属于《东业智慧园区使用协议》不可分割的有效组成部分。
      </p>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
export default {
  components: { Back },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#myselfPrivacy {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
  padding: 0.2rem;
  h5 {
    margin-bottom: 0.1rem;
  }
  .bold {
    font-weight: bold;
  }
  p {
    line-height: 0.15rem;
    font-size: 0.14rem;
    color: #666;
    margin-bottom: 0.08rem;
    .mb20 {
      margin-bottom: 0.2rem;
    }
  }
}
</style>